<template>
    <div class="agreement_page">
        <new-header :config="headerConfig"></new-header>
        <img class="agreement_image" :src="urlData" alt="">
    </div>
</template>
<script>
import newHeader from "@/components/newHeader";
import { getConfig_api } from "../../api/deal"
export default {
    components: {
        newHeader
    },
    data() {
        return {
            headerConfig: {
                show: true,
                title: '入会申请说明',
            },
            urlData: '',
        }
    },
    created() {
        this.getConfig()
    },
    methods: {
        async getConfig() {
            let data = {
                config: ['union_member_application']
            }
            let res = await getConfig_api(data)
            if (res.code == 0) {
                this.urlData = res.data.union_member_application
            }
        }
    }
}
</script>
<style scoped>
.agreement_page {
    width: 100%;
    height: calc(100vh - 92px);
    box-sizing: border-box;
    padding-top: 92px;
    display: flex;
    justify-content: center;
}

.agreement_image {
    width: 710px;
}
</style>